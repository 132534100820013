import React from 'react'
import { Link, useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "./layout"
import Seo from "./seo"
import PageHeader from "./hero"
import BlogCategoryMenu from "./blogCategoryMenu"
import SvgImgSelector from "./svgImgSelector"
import { blogBGColor }  from "../utils/variables"

const BlogCategory = ({ pageContext }) => {
    const data = useStaticQuery(graphql`
        query BlogCategoryQuery {
            allMarkdownRemark(
                sort: { fields: frontmatter___id, order: DESC }
                ) {
                    edges {
                    node {
                        frontmatter {
                            id
                            path
                            fakeData
                            date
                            update
                            title
                            tags
                            categoryTagJP
                            categoryTagEN
                            excerpt
                            featuredImage {
                                childImageSharp {
                                  gatsbyImageData(placeholder: BLURRED, quality: 10)
                                }
                            }
                        }
                    }
                }
            }
        }
        `)
    const { edges } = data.allMarkdownRemark
    const { categoryNameJP, categoryNameEN } = pageContext
    const selectedContents = edges.filter(({ node }) => {
        if(node.frontmatter.categoryTagEN === categoryNameEN) return true
        else return false
    })
    return (
        <Layout page="Blog">
        <Seo title={categoryNameJP} 
             description="monoteinが専門としているハイパフォーマンスなウェブサイトは、SSG、Jamstack、ヘッドレスCMSといった最新テクノロジーで表示スピードを高速化し、ストレスフリーなウェブ体験を提供することで顧客満足度、サイト回遊率、アイテム購入率を高めてホームページを成功に導きます。当ホームページのような高速なコーポレートサイト制作、Jamstackサイト制作、英語版制作などお気軽にお問い合わせください。"
        />
            <div className="blog">
                <PageHeader page="BlogCategory" categoryName={categoryNameJP}/>
                <div className="container">
                    <BlogCategoryMenu/>
                    <div className="grid-container">
                    {selectedContents.map((x, index) => {
                        const { fakeData, date, title, path, tags } = x.node.frontmatter
                        return(
                            <div className="grid-item" key={index}>
                                <Link to={path}>      
                                    <div className="img-container">
                                        <GatsbyImage image={x.node.frontmatter.featuredImage.childImageSharp.gatsbyImageData} alt="xxxx"/>
                                    </div>
                                    <div className="text-container"> 
                                        <h3>{title}</h3>
                                        <p>【 { tags[0] } 】<span>{fakeData ? fakeData : date} </span></p>
                                    </div> 
                                </Link> 
                            </div>
                        )
                    })}
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default BlogCategory